const options = {
  root: document,
  rootMargin: '-30px',
  threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
}

function observeHandler(entries, observer) {
  entries.forEach((el) => {
    el.target.style.opacity = entries[0].intersectionRatio;
    // console.log("OBSERVE", el.target.style.opacity, ob[0].intersectionRatio)
  });
}


function observeHeaderHandler(entries, observer) {


  entries.forEach((entry) => {
    ["shadow", "border-initial", "bg-white/90", "transition", "duration-700"].forEach((s) => {
      if (!entry.isIntersecting) {
        document.querySelector("header")?.classList.add(s)
        document.querySelector("header")?.querySelector('.link-bar')?.classList.remove("opacity-0")
        document.querySelector("header")?.querySelector('.link-bar')?.classList.add("opacity-1")
        document.querySelector("header")?.querySelector('#normal-logo')?.classList.remove("opacity-0")
        document.querySelector("header")?.querySelector('#normal-logo')?.classList.add("opacity-1")



      }
      else {
        document.querySelector("header")?.classList.remove(s)
        document.querySelector("header")?.querySelector('.link-bar')?.classList.remove("opacity-1")
        document.querySelector("header")?.querySelector('.link-bar')?.classList.add("opacity-0")
        document.querySelector("header")?.querySelector('#normal-logo')?.classList.add("opacity-0")

      }

    })
  });
}

const scaleImageobserver = new IntersectionObserver(entries => {
  // We will fill in the callback later...
  // Loop over the entries
  entries.forEach(entry => {
    // If the element is visible
    if (entry.isIntersecting) {
      // Add the animation class
      entry.target.classList.add('square-animation');

    }
  });

});

// Tell the observer which elements to track

try {
  scaleImageobserver.observe(document.querySelector('.square')!)
} catch (error) {
}

// const observer = new IntersectionObserver(observeHandler, options);
const headerObserver = new IntersectionObserver(observeHeaderHandler, { ...options, rootMargin: '32px', threshold: [1] });


// const els = document.querySelectorAll('[data-observe]');
const elsh = document.querySelector('[data-header-observe]');

const contentHeader = document.querySelector('[content-header-observe]');

// els.forEach((el) => {
//   observer.observe(el);
// });

if (elsh) {
  headerObserver.observe(elsh);
  document.querySelector("header")?.classList.remove("shadow-md")
}

if (contentHeader) {
  headerObserver.observe(contentHeader);
  document.querySelector("content_header")?.classList.remove("shadow-md")
}

function validateEmail(): boolean {
  var email = (document.getElementById('contact-email') as HTMLInputElement).value;

  if (email.length == 0) {

    producePrompt('Email Invalid', 'email-error', 'red');
    return false;

  }

  if (!email.match(/^[A-Za-z\._\-[0-9]*[@](.*)[\.][a-z]{2,4}$/)) {

    producePrompt('Email Invalid', 'email-error', 'red');
    return false;

  }

  producePrompt('', 'email-error', 'green');
  return true;

}

function jsShow(id) {
  (document.getElementById(id) as HTMLInputElement).style.display = 'block';
}

function jsHide(id) {
  (document.getElementById(id) as HTMLInputElement).style.display = 'none';
}


function producePrompt(message, promptLocation, color) {

  (document.getElementById(promptLocation) as HTMLInputElement).innerHTML = message;
  (document.getElementById(promptLocation) as HTMLInputElement).style.color = color;
  (document.getElementById(promptLocation) as HTMLInputElement).style.fontWeight = '700';
}


function sendEmail() {
  if (!validateEmail()) {
    return false;
  }
  
  const linebreak = '%0D%0A';

  const messageInput = document.getElementById('contact-message') as HTMLInputElement;
  const nameInput = document.getElementById('contact-name') as HTMLInputElement;
  const companyInput = document.getElementById('company-name') as HTMLInputElement;
  const emailInput = document.getElementById('contact-email') as HTMLInputElement;
  const phoneInput = document.getElementById('contact-phone') as HTMLInputElement;

  let body = `To whom it may concern, ${linebreak}${linebreak}`;
  body += messageInput.value ? `${messageInput.value}${linebreak}${linebreak}` : '';
  body += nameInput ? `${nameInput.value}${linebreak}` : '';
  body += companyInput ? `${companyInput.value}${linebreak}` : '';
  body += emailInput ? `${emailInput.value}${linebreak}` : '';
  body += phoneInput ? `${phoneInput.value}${linebreak}` : '';

  window.location.href = `mailto:rory@dev-pro.ca?subject=Contact DevPro&body=${body}`;

  return false;
}
